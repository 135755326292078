import React from "react"
import "../../works/tft.css"
import Footer from "../../../components/footer"
import WorkHeader from "../../../components/workHeader"
import tft from "../../../../static/work_headers/tft.png"
import Dict from "../../../langs/dict"
import WorkAbout from "../../../components/workAbout"
import Perk from "../../../components/perks"
import tft1 from "../../../../static/works_perks/tft_1.png"
import tft2 from "../../../../static/works_perks/tft_2.png"
import tft3 from "../../../../static/works_perks/tft_3.png"
import Contact from "../../../components/contact"
import tftLogo from "../../../../static/logos/tft_logo.png"
import Layout from "../../../components/layoutWorks"

export default () => {
  const lang = "fr"
  const dict = new Dict(lang)
  return (
    <Layout
      seoTitle="Application Web - tft"
      seoDescription="Pour ce projet, webpanda a créé une application web moderne construite autour du concept de tranparence et communauté. Les utilisateurs peuvent facilement découvrir, ajouter et contacter des #fairspots."
    >
      <div id="works-tft-background">
        <WorkHeader
          title={dict.getTrans("work_tft_title")}
          subTitle={dict.getTrans("work_tft_subtitle")}
          pic={tft}
          navLang={lang}
          hl=" #FFC46E"
          pageUrl="/works/tft"
        />
      </div>
      <WorkAbout
        title={dict.getTrans("tft_about_title")}
        p1={dict.getTrans("tft_about_p1")}
        p2={dict.getTrans("tft_about_p2")}
        logo={tftLogo}
      />
      <div id="works-perks-holder">
        <Perk
          title={dict.getTrans("tft_perk1_title")}
          image={tft1}
          text={dict.getTrans("tft_perk1_text")}
        />
        <Perk
          title={dict.getTrans("tft_perk2_title")}
          image={tft2}
          text={dict.getTrans("tft_perk2_text")}
        />
        <Perk
          title={dict.getTrans("tft_perk3_title")}
          image={tft3}
          text={dict.getTrans("tft_perk3_text")}
        />
      </div>
      <div id="works-tft-contact">
        <Contact lang="en" />
      </div>
      <Footer lang={lang} />
    </Layout>
  )
}
